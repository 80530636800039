import { isGreaterThanOrEqualToN, isRequired } from '@/utilities/validations'
import { getCurrency, getStatus } from '@/utilities/views'
import { title } from '@/utilities/filters'
import { formatCurrency } from '@/utilities/functions'
import { CURRENCIES, JOURNEY_STATUSES } from '@/utilities/constants'
import { getFormattedDateTime, getFormattedJourneyReference } from '@/utilities/org'

const { GBP } = CURRENCIES
const { AGREED, ALL, CLOSED, EXPIRED, OPEN } = JOURNEY_STATUSES

const allSelectedHeaders = (context: Record<string, any>): Record<string, any>[] => {
  const dh = defaultHeaders(context)
  // add comment message removed for empty comments
  const comments = dh.comments
  delete comments.nullText
  delete comments.nullTextColor
  return makeUneditable([
    dh.jobNumber,
    dh.departureDateTime,
    dh.leadPassengerName,
    dh.waypoints,
    dh.vehicleName,
    dh.mileage,
    dh.cancelled,
    dh.fare,
    dh.leadMileage,
    dh.leadMileageCost,
    dh.waitingTimeExpenseMinutes,
    dh.waitingTimeCost,
    dh.expenses,
    dh.totalCost,
    comments,
    dh.status,
  ])
}

export const defaultHeaders = (context: Record<string, any>): Record<string, any> => ({
  cancelled: {
    renderFunction: (v: any) => title(String(context.$t('common.' + (v ? 'yes' : 'no')))),
    text: title(String(context.$t('cmac.cancelled'))),
    value: 'cancelled',
    width: '3%',
  },
  comments: {
    editDialog: { large: true },
    editInput: {
      component: 'c-confirmation-textarea',
      messageClass: 'warning--text text--darken-2',
      message: context.$t('cmac.addingAComment'),
      'full-width': true,
      rows: 8,
      style: `width:${context.editTextareaWidth}px;`,
      placeholder: context.$t('cmac.pleaseAddAComment') + '...',
    },
    nullText: '+ ' + context.$t('cmac.addAComment'),
    nullTextColor: 'accent--text',
    raw: true,
    text: title(context.$tc('plurals.comments', 2)),
    value: 'comments',
    width: '15%',
  },
  departureDateTime: {
    text: title(String(context.$t('cmac.departureDate'))),
    renderFunction: (v: any) => getFormattedDateTime(v),
    value: 'departureDateTime',
  },
  fare: {
    text: title(String(context.$t('cmac.fare'))),
    vueComponent: { name: 'c-fare-calculation-popup' },
    value: 'fare',
  },
  jobNumber: {
    renderFunction: (v: any) => getFormattedJourneyReference(v),
    text: title(String(context.$t('common.jobNo'))) + '.',
    value: 'jobNumber',
    vueComponent: {
      name: 'c-copy-to-clipboard',
      props: { raw: true },
    },
    width: '4%',
  },
  leadMileage: {
    editInput: {
      type: 'number',
      rules: [isGreaterThanOrEqualToN(0), isRequired],
    },
    nullText: '-',
    nullTextColor: 'primary--text text--lighten-3',
    renderFunction: (v: any) => (v === -1 || !v ? '-' : Number.parseFloat(v).toFixed(1)),
    parseReturnValue: (v: any) => Number(v),
    text: title(String(context.$t('cmac.leadMileage'))),
    value: 'leadMileage',
    width: '3%',
  },
  /*
  This next column is used to render the icon to view the lead mileage route
  that was used to calculate the lead mileage number of miles
  It uses the id of the journey as the value to pass to the vue component
  */
  id: {
    value: 'id',
    width: '0.5%',
    vueComponent: {
      name: 'c-leadmileage',
    },
  },
  leadMileageCost: {
    text: title(String(context.$t('cmac.leadMileageCost'))),
    renderFunction: (v: any) => formatExpense(v),
    value: 'leadMileageCost',
    width: '3%',
  },
  leadPassengerName: {
    text: title(String(context.$t('cmac.passenger'))),
    value: 'leadPassengerName',
  },
  mileage: {
    text: title(String(context.$t('cmac.mileage'))),
    renderFunction: (v: any) => (v === -1 || !v ? '-' : Number.parseFloat(v).toFixed(1)),
    nullText: '-',
    value: 'mileage',
    width: '3%',
  },
  expenses: {
    editInput: {
      component: 'c-expense-input',
      type: 'number',
    },
    nullText: context.$t('cmac.addExpense'),
    nullTextColor: 'accent--text',
    renderFunction: (v: Array<any>) => formatExpenses(v),
    text: title(String(context.$t('cmac.expenses'))),
    value: 'expenses',
    width: '10%',
    raw: true,
  },
  status: {
    text: title(String(context.$t('common.status'))),
    renderFunction: (v: any) => getStatus(v),
    value: 'status',
    width: '5%',
  },
  vehicleName: {
    text: title(String(context.$t('cmac.vehicle'))),
    value: 'vehicleName',
    width: '5%',
  },
  totalCost: {
    text: title(String(context.$t('cmac.total'))),
    renderFunction: (v: any) => formatTotal(v),
    value: 'total',
    width: '5%',
  },
  waitingTimeCost: {
    text: title(String(context.$t('cmac.waitingTimeCost'))),
    renderFunction: (v: any) => formatExpense(v),
    value: 'waitingTimeCost',
    width: '4%',
  },
  waitingTimeExpenseMinutes: {
    renderFunction: (v: any) => Number(v),
    editInput: {
      type: 'number',
      rules: [isRequired, isGreaterThanOrEqualToN(0)],
    },
    parseReturnValue: (v: any) => Number(v),
    text: title(String(context.$t('cmac.waitingTimeMinutes'))),
    value: 'waitingTimeExpenseMinutes',
    width: '3%',
  },
  waypoints: {
    raw: true,
    text: title(context.$tc('plurals.journeys', 1)),
    value: 'waypoints',
    vueComponent: { name: 'c-waypoints' },
    width: '20%',
  },
})

const formatExpenses = (e: Record<string, any>[]): string =>
  e.length === 0
    ? 'Add Expense'
    : e
        .filter(x => !!x.cost?.net)
        .map(x => `${x.expenseType}: ${formatExpense(x)}`)
        .join('<br/>')

const formatExpense = (e: Record<string, any> | null): string =>
  formatCurrency(
    e?.cost?.net || e?.net || 0,
    getCurrency(e?.cost?.currency || e?.currency || 'GBP')
  )

const formatTotal = (e: Record<string, any> | null): string =>
  formatCurrency(Number(e?.total), getCurrency(e?.cost?.currency || e?.currency || GBP))

export const headers = (context: Record<string, any>): Record<string, any>[] => {
  switch (context.searchFields.status) {
    case AGREED:
      return allSelectedHeaders(context)
    case ALL:
      return allSelectedHeaders(context)
    case EXPIRED:
      return allSelectedHeaders(context)
    case CLOSED:
      return allSelectedHeaders(context)
    case OPEN:
      return openHeaders(context)
    default:
      return allSelectedHeaders(context)
  }
}

const makeUneditable = (selected: Record<string, any>[]): Record<string, any>[] => {
  // to make readonly editInput and editDialog properties are removed from all elements
  // and editable is set to false
  return selected.map((v: Record<string, any>) => {
    delete v.editDialog
    delete v.editInput
    v.editable = false
    return v
  })
}

const openHeaders = (context: Record<string, any>): Record<string, any>[] => {
  const dh = defaultHeaders(context)
  return [
    dh.jobNumber,
    dh.departureDateTime,
    dh.leadPassengerName,
    dh.waypoints,
    dh.vehicleName,
    dh.mileage,
    dh.cancelled,
    dh.fare,
    dh.leadMileage,
    dh.leadMileageCost,
    dh.id,
    dh.waitingTimeExpenseMinutes,
    dh.waitingTimeCost,
    dh.expenses,
    dh.totalCost,
    dh.comments,
  ]
}
